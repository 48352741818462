const state = {
	isLoading: true,
	productList: [
		{
			id: 1,
			title: 'Garena Shells 725',
			thumbnail_url: require('../../assets/img/TW.jpg'),
			price: 725,
			quantity: 10,
			category: "macbook",
			description: ""
		},
		{
			id: 2,
			title: 'Garena Shells 1000',
			thumbnail_url: require('../../assets/img/touch.jpg'),
			price: 1000,
			quantity: 10,
			category: "macbook",
			description: ""
		},
		{
			id: 3,
			title: 'Garena Shells 2000',
			thumbnail_url: require('../../assets/img/Spotify.jpg'),
			price: 2000,
			quantity: 10,
			category: "macbook",
			description: ""
		},
		{
			id: 4,
			title: 'PlayStation Wallet 10',
			thumbnail_url: require('../../assets/img/primark.jpg'),
			price: 10,
			quantity: 10,
			category: "macbook",
			description: ""
		},
		{
			id: 5,
			title: 'PlayStation Wallet 20',
			thumbnail_url: require('../../assets/img/NF.jpg'),
			price: 20,
			quantity: 10,
			category: "macbook",
			description: ""
		},
		{
			id: 6,
			title: 'PlayStation Wallet 50',
			thumbnail_url: require('../../assets/img/mgtv.jpg'),
			price: 50,
			quantity: 10,
			category: "macbook",
			description: ""
		},
		{
			id: 7,
			title: 'Paywiser Voucher 5',
			thumbnail_url: require('../../assets/img/Lazada.jpg'),
			price: 5,
			quantity: 10,
			category: "macbook",
			description: ""
		},
		{
			id: 8,
			title: 'Paywiser Voucher 50',
			thumbnail_url: require('../../assets/img/grab.jpg'),
			price: 50,
			quantity: 10,
			category: "macbook",
			description: ""
		},
		{
			id: 9,
			title: 'Paywiser Voucher 100',
			thumbnail_url: require('../../assets/img/deezer.jpg'),
			price: 100,
			quantity: 10,
			category: "macbook",
			description: ""
		},
		{
			id: 10,
			title: 'Paywiser Voucher 500',
			thumbnail_url: require('../../assets/img/areem.jpg'),
			price: 500,
			quantity: 10,
			category: "macbook",
			description: ""
		},
		{
			id: 11,
			title: 'Paywiser Voucher 1000',
			thumbnail_url: require('../../assets/img/Amazon.jpg'),
			price: 1000,
			quantity: 10,
			category: "macbook",
			description: ""
		},
	]
}

const mutations = {
	'UPDATE_PRODUCT_LIST' (state) {
		state.productList 
		state.isLoading = false;
	}
}

const actions = {

}

const getters = {
	products: (state) => {
		return state.productList;
	},
	isProductLoading: (state) => {
		return state.isLoading;
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}