<template>
  <div class="container table-responsive">
    <table id="cart" class="table table-hover table-sm">
      <thead>
        <tr>
          <th style="width:50%">Product</th>
          <th style="width:10%">Price</th>
          <th style="width:8%">Quantity</th>
          <th style="width:22%" class="text-center">Subtotal</th>
          <th style="width:10%"></th>
        </tr>
      </thead>

      <transition-group name="list-shopping-cart" tag="tbody">
        <app-cart-item
          v-for="cartItem in cartItemList"
          :cartItem="cartItem"
          :key="cartItem.id"
        ></app-cart-item>
      </transition-group>

      <tfoot>
        <tr class="d-table-row d-sm-none">
          <td class="text-center">
            <strong>總數 NT${{ cartValue }}</strong>
          </td>
        </tr>
        <!-- <tr>
          <div class="bank-information">
              <div>
                <span>{{information.BeneficiaryBank}}</span>
                <p>{{information.BeneficiaryBankCode}}</p>
                <span>{{information.ReceivingBankAccount}}</span>
                <p>{{information.BankAccount}}</p>
                <span>{{information.branchName}}</span>
                <p>{{information.branchNameValue}}</p>
                <span>{{information.branchBankCode}}</span>
                <p>{{information.branchBankCodeValue}}</p>
                <span>{{information.accountName}}</span>
                <p>{{information.accountNameValue}}</p>
                <span>{{information.orderNumber}}</span>
                <p>{{information.orderNumberValue}}</p>
              </div>
          </div>
        </tr> -->
        <tr>
          <td>
            <button class="btn btn-warning" @click="saveShoppingCartLocal">
              <i class="fa fa-angle-left"></i> 新增訂單
            </button>
          </td>
          <td colspan="2" class="d-none d-sm-table-cell">
          </td>
          <td class="d-none d-sm-table-cell text-center">
            <strong>總數 NT${{ cartValue }}</strong>
          </td>
          <td class="px-0">
            <button class="btn btn-success" @click="checkout">
              <span class="text-nowrap"
                >進行結算 <i class="fa fa-angle-right d-inline"></i
              ></span>
            </button>
            <!-- <span @click="Print">打印</span> -->
          </td>
        </tr>
      </tfoot>
    </table>
    <div class="bank-information" id="bank-information">
              <div class="cartValue">
                <span>轉賬金額</span>
                <p>{{ cartValue }}<span>TWD</span></p>
              </div>
              <div>
                <span>{{information.BeneficiaryBank}}</span>
                <p>{{information.BeneficiaryBankCode}}</p>
                <span>{{information.ReceivingBankAccount}}</span>
                <p>{{information.BankAccount}}</p>
                <span>{{information.branchName}}</span>
                <p>{{information.branchNameValue}}</p>
                <span>{{information.branchBankCode}}</span>
                <p>{{information.branchBankCodeValue}}</p>
                <span>{{information.accountName}}</span>
                <p>{{information.accountNameValue}}</p>
                <span>{{information.orderNumber}}</span>
                <p>{{strs}}</p>
              </div>
          </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CartItem from './cart/CartItem.vue'
export default {
  data(){
     return {
     list: [
        {
          BeneficiaryBank:"收款銀行及代碼",
          BeneficiaryBankCode:"國泰世華商業銀行-013",
          ReceivingBankAccount:"收款銀行帳戶",
          BankAccount:"46711116102124",
          branchName:"分行名稱",
          branchNameValue:"大昌分行",
          branchBankCode:"分行代碼",
          branchBankCodeValue:"2022",
          accountName:"帳戶名稱",
          accountNameValue:"旺沛大數位股份有限公司",
          orderNumber:"訂單編號",
          orderNumberValue:"1668566424156566"
        },
        {
          BeneficiaryBank:"收款銀行及代碼",
          BeneficiaryBankCode:"國泰世華商業銀行-013",
          ReceivingBankAccount:"收款銀行帳戶",
          BankAccount:"46711116109019",
          branchName:"分行名稱",
          branchNameValue:"大昌分行",
          branchBankCode:"分行代碼",
          branchBankCodeValue:"2022",
          accountName:"帳戶名稱",
          accountNameValue:"旺沛大數位股份有限公司",
          orderNumber:"訂單編號",
          orderNumberValue:"1668566458087389"
        },
        {
          BeneficiaryBank:"收款銀行及代碼",
          BeneficiaryBankCode:"國泰世華商業銀行-013",
          ReceivingBankAccount:"收款銀行帳戶",
          BankAccount:"46711116106597",
          branchName:"分行名稱",
          branchNameValue:"大昌分行",
          branchBankCode:"分行代碼",
          branchBankCodeValue:"2022",
          accountName:"帳戶名稱",
          accountNameValue:"旺沛大數位股份有限公司",
          orderNumber:"訂單編號",
          orderNumberValue:"1668566511688383"
        },
        {
          BeneficiaryBank:"收款銀行及代碼",
          BeneficiaryBankCode:"國泰世華商業銀行-013",
          ReceivingBankAccount:"收款銀行帳戶",
          BankAccount:"46711116107441",
          branchName:"分行名稱",
          branchNameValue:"大昌分行",
          branchBankCode:"分行代碼",
          branchBankCodeValue:"2022",
          accountName:"帳戶名稱",
          accountNameValue:"旺沛大數位股份有限公司",
          orderNumber:"訂單編號",
          orderNumberValue:"1668566481149361"
        },
        {
          BeneficiaryBank:"收款銀行及代碼",
          BeneficiaryBankCode:"國泰世華商業銀行-013",
          ReceivingBankAccount:"收款銀行帳戶",
          BankAccount:"46711116108877",
          branchName:"分行名稱",
          branchNameValue:"大昌分行",
          branchBankCode:"分行代碼",
          branchBankCodeValue:"2022",
          accountName:"帳戶名稱",
          accountNameValue:"旺沛大數位股份有限公司",
          orderNumber:"訂單編號",
          orderNumberValue:"1668566497545491"
        },
      ],
      information:{},
      strs:""
     }
  },
  computed: {
    ...mapGetters([
      'cartItemList',
      'isLoggedIn',
      'products',
      'currentUser',
      'cartValue',
    ]),
  },
  components: {
    appCartItem: CartItem,
  },
  methods: {
     createNonceStr(){
        let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        for (let i = 0; i < 16; i++) {
          let id = parseInt(Math.random() * 10);
          this.strs += chars[id];
        }
    },

    Print(){
       var n = Math.floor(Math.random() * this.list.length + 1)-1;
      //  console.log(this.information.BankAccount)
      this.information = JSON.parse(JSON.stringify(this.list[n]))
      document.getElementById("bank-information").style.display ="block"
    },
    ...mapActions([
      'saveShoppingCart',
      'addMessage',
      'saveToTransaction',
      'clearCart',
    ]),
    checkValidCart(itemList, prodList) {
      let isValid = true
      let message = ''

      itemList.map((item) => {
        for (let prodIdx = 0; prodIdx < prodList.length; prodIdx++) {
          if (prodList[prodIdx].id == item.id) {
            if (prodList[prodIdx].quantity < item.quantity) {
              message = `Only ${prodList[prodIdx].quantity} ${item.title} available in stock`
              isValid = false
              return
            }
            break
          }
        }
      })
      return {
        isValid,
        message,
      }
    },
    saveShoppingCartLocal() {
      if (this.isLoggedIn) {
        let { isValid, message } = this.checkValidCart(
          this.cartItemList,
          this.products
        )

        if (isValid) {
          this.saveShoppingCart({
            cartItemList: this.cartItemList,
            uid: this.currentUser.uid,
          }).then(() => {
            this.addMessage({
              messageClass: 'success',
              message: 'Your shopping cart is saved successfully',
            })
            this.$router.push('/')
          })
        } else {
          this.addMessage({
            messageClass: 'danger',
            message: message,
          })
        }
      } else {
        this.addMessage({
          messageClass: 'warning',
          message: 'Please login to save your cart',
        })
      }
    },
    checkout() {
      if (this.isLoggedIn) {
        if (!this.cartItemList || this.cartItemList.length == 0) {
          this.addMessage({
            messageClass: 'warning',
            message: 'Your cart is empty!',
          })
          console.log("111")
          return
        }
        let { isValid, message } = this.checkValidCart(
          this.cartItemList,
          this.products
        )

        if (isValid) {
          this.saveToTransaction({
            cartItemList: this.cartItemList,
            uid: this.currentUser.uid,
          }).then(() => {
            console.log("222")
            if(this.information.BankAccount===undefined){
              this.Print()
              this.createNonceStr()
            }
            this.addMessage({
              messageClass: 'success',
              message: 'Your order has been successfully processed!',
            })
            this.saveShoppingCart({
              cartItemList: [],
              uid: this.currentUser.uid,
            })
            // this.clearCart()
            // this.$router.push('/')
          })
        } else {
          console.log("333")
          this.addMessage({
            messageClass: 'danger',
            message: message,
          })
        }
      } else {
        console.log("444")
        this.addMessage({
          messageClass: 'warning',
          message: 'Please login to checkout',
        })
        this.$router.push('/register')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-shopping-cart-leave-active {
  transition: all 0.4s;
}

.list-shopping-cart-leave-to {
  opacity: 0;
  transform: translateX(50px);
}

.table-sm {
  font-size: 0.875rem;
  ::v-deep h4 {
    font-size: 1.25rem;
  }
}

.bank-information {
  // width: auto;
  margin-top: 2rem;
  display: none;
  .cartValue{
    text-align: center;
  }

  >div {
    span {
      color: #c9c9c9;
    }

    p {
      font-size: 1.5rem;
      color: #142d58;
      span{
        font-size: 0.6rem;
        color: #142d58;
      }
    }
  }

}
</style>
