import Store from './components/Store.vue';
import ShoppingCart from './components/ShoppingCart.vue';
import ProductDetails from './components/ProductDetails.vue';
import Login from './components/auth/Login.vue';
import Register from './components/auth/Register.vue';

export const routes = [
	//uat
	// {path: '/onlinestore', component: Store, name: 'mainpage'},
	// {path: '/onlinestore/product/:id', component: ProductDetails, name: 'product'},
	// {path: '/onlinestore/cart', component: ShoppingCart, name: 'shoppingcart'},
	// {path: '/onlinestore/login', component: Login, name: 'login', onlyGuest: true },
	// {path: '/onlinestore/register', component: Register, name: 'register', onlyGuest: true},

	//prd
	{path: '/', component: Store, name: 'mainpage'},
	{path: '/product/:id', component: ProductDetails, name: 'product'},
	{path: '/cart', component: ShoppingCart, name: 'shoppingcart'},
	{path: '/login', component: Login, name: 'login', onlyGuest: true },
	{path: '/register', component: Register, name: 'register', onlyGuest: true},
	{path: '*', redirect: '/' }
];
// export default new VueRouter({
// 	mode:"history",
// 	base:"/web",
	
// })